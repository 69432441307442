.main_eco{
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ecobox{
    display: flex;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.847);
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 2rem;
    color: rgb(43, 42, 42);
}


.ecoleft img{
   width: 100px;
   height: 100px;
}

.ecoright{
    width: 70%;
    padding-left: 30px;
    border-left: 1px solid white;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
}
.ecoright-down{
    text-align: right;
}

/* .ecoright-downbutton{
   background-color: transparent;
   color: blue;
   border: none;
} */

p{
    font-weight: 600;
}

.ecoright-downbutton {
    display: inline-block;
    background-color: rgba(61, 0, 61, 0.9); /* Button background color */
    color: #fff; /* Button text color */
    padding: 10px 20px; /* Button padding */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    font-weight: 500; /* Font weight */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.ecoright-downbutton:hover {
    background-color: rgba(51, 0, 51, 0.9); /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.ecoright-downbutton:active {
    background-color: rgba(51, 0, 51, 0.7); /* Even darker shade on click */
    transform: scale(1); /* Return to original size on click */
}



/* media quaries */

@media (max-width:768px) {
    .ecobox {
       flex-direction: column;
    }
    .ecoleft{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ecoleft img{
        width: 70%;
        height: auto;
     }
  }

  @media (max-width:520px) {
    .ecoleft img{
        width: 100%;
        height: auto;
     }
  }

  .mainecorow{
    width: 100%;
  }