.SignUp-main{
    width: 100%;
    min-height: 100vh;
    background-image:url("./upboxbg1.png");
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signUp-container{
width:500px;
padding: 20px;
background-color: #322f2f79;
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
display: flex;
text-align: center;
justify-content: center;
flex-direction: column;
border-radius: 20px;
}

.signUp-container h2{
  margin-bottom: 2rem;
    }
    

    .Up-form-group input[type="password"],
    .Up-form-group input[type="string"],
    .Up-form-group input[type="email"]{
      border: none;
      border-bottom: 2px solid #ccc;
      background-color: transparent;
      outline: none;
      transition: border-color 0.3s;
    }
    
    .Up-form-group input[type="password"]::-webkit-outer-spin-button,
    .Up-form-group input[type="string"]::-webkit-outer-spin-button,
    .Up-form-group input[type="email"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }


.Up-form-group input{
    color: #ffffff;
    width: 70%;
    padding: 5px 10px;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
}

.signupbtn{
    margin-bottom: 1.5rem;

}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* .modal-overlay .modal-content {
  background-color: white;
  padding: 20px;
  color: #000;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 80%;
} */

.modal-overlay .modal-content h3 {
  margin-bottom: 10px;
  color: red;
}

.modal-overlay .modal-content p {
  margin-bottom: 20px;
}

.btn.btn-secondary {
  padding: 10px 20px;
  background-color: #007bff;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn.btn-secondary:hover {
  background-color: #0056b3;
}
