/* -- Social Icons -- */
.social {
  margin-top: 1.5rem;
  text-align: center;
}

.social h2 {
  margin-bottom: 2rem;
}

.social a {
  color: #fff;
}

.Xsocialicon,
.Tsocialicon,
.Dsocialicon,
.Ysocialicon,
.Fsocialicon {
  padding: 10px;
  background-color: rgba(51, 100, 169, 0.47);
  border-radius: 50%;
  margin: 0 1.2rem;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.Tsocialicon {
  border: 5px solid rgba(51, 100, 169, 0.47);
}

.Xsocialicon:hover,
.Tsocialicon:hover,
.Dsocialicon:hover,
.Ysocialicon:hover,
.Fsocialicon:hover {
  background-color: #9fc7ff;
}

.Tsocialicon:hover {
  border-color: #9fc7ff;
  background-color: #ffffff;
}

.social p {
  font-size: 18px;
  margin-top: 2rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .Xsocialicon,
  .Tsocialicon,
  .Dsocialicon,
  .Ysocialicon,
  .Fsocialicon {
    padding: 8px;
    margin: 0 1rem;
  }
}

@media (max-width: 480px) {
  .Xsocialicon,
  .Tsocialicon,
  .Dsocialicon,
  .Ysocialicon,
  .Fsocialicon {
    padding: 6px;
    margin: 0 0.8rem;
  }

  .social p {
    font-size: 16px;
  }
}
