.mainProfile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url(./upboxbg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .profile-box1 {
    background-color: #140f453a;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 60%;
    min-height: 70vh;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.656);
    text-align: center;
  }
  
  .profile-details {
    margin-top: 20px;
  }
  
  .profile-details p {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .btn-logout {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-logout:hover {
    background-color: #ff1a1a;
  }
  
  @media (max-width:600px) {
    .profile-box1 {
      width: 80%;
    }
  }

  @media (max-width:500px) {
    .profile-box1 {
      width: 95%;
    }
  }