/* General Styles */
.main_blog {
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogbox {
    display: flex;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.847);
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 2rem;
    color: rgb(43, 42, 42);
}

.blogleft img {
    width: 100px;
    height: 100px;
}

.blogright {
    width: 70%;
    padding-left: 30px;
    border-left: 1px solid white;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
}

.blogright-down {
    text-align: right;
}

.blogright-downbutton {
    background-color: transparent;
    color: blue;
    border: none;
}

/* Modal Styles */
.blogmodal {
    position: fixed;
    top: 0; /* Adjust to account for the navbar */
    left: 0;
    width: 100%;
    height: 100%; /* Full height of the viewport */
    background-color: rgba(203, 190, 190, 0.8); /* Slightly transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(43, 42, 42);
    overflow: auto; /* Enable scrolling if content overflows */
    z-index: 9999; /* Ensure the modal is above other content */
}

.blogmodal-content {
    width: 90%;
    max-width: 800px;
    background: #fff;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    max-height: 90vh; /* Limit height to fit in the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    z-index: 10000; /* Ensure it's above the background */
}

.blogmodal-image {
    width: 100%; /* Adjust to fill available width */
    max-width: 300px; /* Ensure it doesn’t exceed the max width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px;
    margin-top: 2rem;
}

.blogmodal-detail {
    width: 100%; /* Full width for better alignment */
    margin-bottom: 0.5rem;
}

.blogcancel {
    position: absolute;
    top: 10px; /* Adjust for padding */
    right: 10px; /* Adjust for padding */
    padding: 5px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(137, 128, 128, 0.867);
    z-index: 10001; /* Ensure it's clickable */
}

.blogcancel:hover {
    cursor: pointer;
    background-color: rgba(147, 146, 150, 0.488);
}

.blogmodal-video {
    margin-top: 20px;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden; /* Ensure no overflow */
    z-index: 10000; /* Make sure it’s clickable */
}

.blogmodal-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; /* Remove border if any */
    pointer-events: auto; /* Ensure iframe is clickable */
    z-index: 10000; /* Ensure it's on top */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .blogbox {
        flex-direction: column;
    }

    .blogleft {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blogleft img {
        width: 70%;
        height: auto;
    }

    .blogmodal {
        top: 0; /* Reset top position for mobile */
        height: 100%; /* Full viewport height */
        z-index: 9999; /* Ensure it's on top */
    }

    .blogmodal-video {
        z-index: 10000; /* Ensure it's on top */
    }

    .blogmodal-content {
        z-index: 10000; /* Ensure it's on top */
    }
}

@media (max-width: 520px) {
    .blogleft img {
        width: 100%;
        height: auto;
    }

    .blogmodal {
        top: 0; /* Reset top position for smaller screens */
        height: 100%; /* Full viewport height */
        z-index: 9999; /* Ensure it's on top */
    }

    .blogmodal-video {
        z-index: 10000; /* Ensure it's on top */
    }

    .blogmodal-content {
        z-index: 10000; /* Ensure it's on top */
    }
}
